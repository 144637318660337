import { render, staticRenderFns } from "./LoginAdminView.vue?vue&type=template&id=963255ec&scoped=true&"
import script from "./LoginAdminView.vue?vue&type=script&lang=js&"
export * from "./LoginAdminView.vue?vue&type=script&lang=js&"
import style0 from "../scss/estilos.scss?vue&type=style&index=0&id=963255ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "963255ec",
  null
  
)

export default component.exports